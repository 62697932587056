import { useLoaderData, LosseContent as Content } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Text, Vacancy } from '~/graphql/types'
import type { LoaderData } from '@ubo/losse-sjedel'

export default function Detail({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const { page } = useLoaderData<LoaderData<Vacancy>>()
  const data = JSON.parse(page.data?.data || '')

  const mediaUrl = data?.Media?.VideoUrls?.Url?.[0] || null

  console.log(mediaUrl)

  return (
    <section id="Detail">
      <div className="container py-5 md:py-10 xl:py-20">
        <div className="grid grid-cols-3">
          <div className="col-span-3 lg:col-span-2 flex flex-col gap-16 lg:pr-10">
            <div className="flex flex-col gap-8 children-h2:text-xl md:children-h2:text-2xl lg:children-h2:text-3xl children-h2:font-nord children-h2:font-medium children-h2:text-outrageous-orange children-h2:pb-3">
              {/* FUNCTIE */}
              <div>
                {data.Descriptions.FunctionDescription && <Content className="content">{data.Descriptions.FunctionDescription}</Content>}
              </div>

              {data.Descriptions.AdditionalDescription && (
                <div className="relative">
                  <svg className="absolute left-0 -top-5 sm:top-0" width="64" height="64" viewBox="0 0 24 24">
                    <path
                      d="M3.691 6.292C5.094 4.771 7.217 4 10 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 6.925 10H10a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2H3a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789zM20 20h-6a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789C16.094 4.771 18.217 4 21 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 17.925 10H21a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2z"
                      className="fill-outrageous-orange"
                    />
                  </svg>
                  <Content className="content children:italic children-p:!leading-[1.8] children-p:text-xl max-sm:pt-12 sm:py-10 sm:px-20">
                    {data.Descriptions.AdditionalDescription}
                  </Content>
                </div>
              )}

              {/* OVER DE OPDRACHTGEVER */}
              <div>
                <h2>Over de opdrachtgever</h2>
                {data.Descriptions.ClientDescription && (
                  <Content className="content">{data.Descriptions.ClientDescription.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}</Content>
                )}
              </div>

              {/* {page.contentType?.node?.name === 'internalVacancy' && ( */}
              <div>
                {/* EISEN */}
                <h2>Eisen</h2>
                {data.Descriptions.RequirementsDescription && (
                  <Content className="content">{data.Descriptions.RequirementsDescription}</Content>
                )}
              </div>
              {/* )} */}

              {mediaUrl && (
                <div className="sm:px-20 py-4 sm:py-10">
                  <iframe
                    className="aspect-video"
                    width="100%"
                    height="auto"
                    src={mediaUrl}
                    title="Yesway - Jouw werk, Jouw leven"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              )}

              <div>
                {/* WAT WIJ BIEDEN */}
                <h2>Wat wij bieden</h2>
                {data.Descriptions.OfferDescription && <Content className="content">{data.Descriptions.OfferDescription}</Content>}
              </div>
              {/* <div> */}
              {/* MEER INFORMATIE */}
              {/* <h2>Meer informatie</h2>
                {data.Descriptions.AdditionalDescription && (
                  <Content className="content">{data.Descriptions.AdditionalDescription}</Content>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
